@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: white;
  scroll-behavior: smooth;
}


input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
  border: 0 !important;
  outline: 0 !important;
}

.flex-colo {
  @apply flex justify-center items-center flex-col
}

.flex-rows {
  @apply flex justify-center items-center
}

.transitions {
  @apply transition ease-in duration-300
}

.zIndex {
  z-index: 1000;
}

.imageHeight {
  height: 450px;
}

.swiper-horizontal>.swiper-pagination-bullets {
  bottom: 0px !important;
}

.swiper-pagination-bullet {
  width: 40px !important;
  height: 5px !important;
  background: #0B0F29 !important;
  opacity: 100 !important;
  border-radius: 0 !important;
  cursor: pointer !important;
}

.swiper-pagination-bullet-active {
  background: #3100FD !important;
}